//@ts-check
import React from 'react';
import '../index.css';
import { useNavigate } from 'react-router-dom';
import { API, Amplify, Storage, graphqlOperation } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { createFuelData } from '../graphql/mutations';
import { useState } from 'react';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { Form, Button, Upload, DatePicker, Row, Col, Input, Select, InputNumber, Radio, Typography, notification, Tooltip } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { fetchInstructionsForUser } from './instruction-util';
import { loadAllGasStations } from './gasstation-util';
import { Predictions, AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

Amplify.register(Predictions);
Predictions.addPluggable(new AmazonAIPredictionsProvider());

export function Home({ loginUser }) {
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission
    const [fileList, setFileList] = useState([]);
    const [instructions, setInstructions] = useState("");
    const [fuelLevel, setFuelLevel] = useState(null);
    const [fuelGrade, setFuelGrade] = useState("");
    const [fuelDate, setFuelDate] = useState("");
    const [allGasStations, setAllGasStations] = useState([]);
    const [selectedGasStation, setSelectedGasStation] = useState("");
    const [numberOfGallons, setNumberOfGallons] = useState(null);
    const [amount, setAmount] = useState(null);
    const [refillType, setRefillType] = useState(null);
    const [extraClicks, setExtraClicks] = useState(0);
    const [comment, setComment] = useState('');
    const [groupId, setGroupId] = useState('');
    const [vin, setVin] = useState('');
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();
    const { TextArea } = Input;
    const { Text } = Typography;

    function logOut() {
        signOut();
        navigate('/login');
    }
    useEffect(() => {
        console.log('useEffect: loginUser in home', loginUser);
        if (loginUser) {
            console.log('useEffect: route value in home', route);
            fetchInstructionsForUser(loginUser, setInstructions, setGroupId, setVin);
            console.log('useEffect: ', 'loginUser: ', loginUser, 
            'groupId: ', groupId, 'vin: ', vin, 'instructions:', instructions);
            loadAllGasStations(setAllGasStations);
        }
    }, [groupId, instructions, loginUser, route, vin]);

    const onFinishFailed = errorInfo => {
        // Get the error message from the errorInfo object
        const errorMessage = errorInfo.errorFields[0].errors[0];
        console.error('Error in onFinishFailed:', errorMessage);
        notification.open({
            message: 'Error',
            description: 'Failed to successfully save data. Please try again.',
            duration: 5, // The notification will close after 3 seconds (optional)
            style: {
                width: 300, // Customize the width of the notification (optional)
            },
        });

      };    

    const onFinish = async (values) => {
        if (!isSubmitting) {
        }
        try {
            setIsSubmitting(true); // Set submitting state to true
            console.log("onFinish values starts");
            console.log(values);
            console.log("Values to be saved start here");
            console.log("instructions:", instructions, "fuelLevel:", fuelLevel, "fuelGrade:", fuelGrade,
                "fuelDate:", fuelDate, "allGasStations:", allGasStations,
                "selectedGasStation:", selectedGasStation, "numberOfGallons:", numberOfGallons, "amount:", amount,
                "refillType:", refillType, "extraClicks:", extraClicks, "comment:", comment, "route:", route);
            console.log("Values to be saved end here");
            console.log("Going to save file");
            if (fileList.length === 0) {
                console.error('Please select a file.');
                return;
            }
            const file = fileList[0].originFileObj;
            const id = uuid();
            const fileName = loginUser + '/' + vin + '/' + id + '/receipt';
            console.log("fileName:", fileName);
            const { key } = await Storage.put(fileName, file, { contentType: file.type });
            console.log("Uploaded s3 key", key);
            console.log("Trying to identify text");
            const response = await identifyTextInPhoto(file);
            console.log("Text identification completed with response: ", response);

            let fuelReceiptText = ""
            if (response)
                fuelReceiptText = response?.text.lines.join('\n');

            const createFuelDataInput = {
                id: id,
                email: loginUser,
                vin,
                time: fuelDate,
                fuelLevel,
                gasStation: selectedGasStation,
                fuelGrade,
                gallons: numberOfGallons,
                cost: amount,
                refillType,
                extraClicks,
                fuelReceiptLocation: key,
                fuelReceiptText,
                comments: comment
            };
            await API.graphql(graphqlOperation(createFuelData, { input: createFuelDataInput }));
            console.log("onFinish values ends");
            console.log('Form submitted:', values);
            console.log('Record created with:', id);
            navigate("/saveconfirmation");

        } catch (error) {
            console.error('Error in onFinish:', error);
            notification.open({
                message: 'Error',
                description: 'Failed to successfully save data. Please try again.',
                duration: 5, // The notification will close after 3 seconds (optional)
                style: {
                    width: 300, // Customize the width of the notification (optional)
                },
            });
        } finally {
            setIsSubmitting(false); // Reset submitting state after submission
        }
    }
    function onSelectFuelDate(momentDate, dateString) {
        if(momentDate) {
            console.log("Fuel date:", momentDate.format('YYYY-MM-DD HH:mm:ss Z'),
            "Epoch", momentDate.valueOf(),
            "Epoch in seconds:", Math.floor(momentDate.valueOf() / 1000),
            "Fuel dateString:", dateString);
        setFuelDate(momentDate.format('YYYY-MM-DD HH:mm:ss Z'));
        } else {
            console.log("Fuel date not set: ", momentDate, dateString);
            setFuelDate("");
            }
    }

    const handleAmountChange = (value) => {
        setAmount(value);
    };

    const handleRefillTypeChange = (e) => {
        setRefillType(e.target.value);
    };

    const handleExtraClicksChange = (value) => {
        setExtraClicks(value);
    };

    const validateExtraClicks = (_, value) => {
        if (refillType === 'full' && value === undefined) {
            return Promise.reject(new Error(''));
        }
        return Promise.resolve();
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };
    const onFileChange = (info) => {
        setFileList(info.fileList.slice(-1)); // Allow only one file
    };
// Custom validation rule for the number of gallons
const validateGallons = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Please enter number of gallons with two or three decimal places'));
    }
    if (!/^\d{1,2}\.\d{2,3}$/.test(value)) {
        return Promise.reject(new Error('Number of gallons must have one or two digits before the decimal and two or three decimal places'));
    }
    setNumberOfGallons(value);
    return Promise.resolve();
  };

  const identifyTextInPhoto = async (photoFile) => {
    try {
      const response = await Predictions.identify({
        text: {
          source: {
            file: photoFile
          }
        }
      });
      return response;
    } catch (error) {
        console.log("Failed to extract text:", error);
    }
  };
  

    return (
        <>
            {(!loginUser) ? (
                <>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <img src="image/logoFinal.png" alt='logo' style={{
                            height: '100px', width: '200px', margin: 'auto'
                        }} className='d-flex justify-content-center' />
                        <Button type="primary"
                            className="logout-btn"
                            onClick={logOut}
                        >Login</Button>
                    </nav>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <h5 style={{ margin: 'auto' }} className='d-flex cloud-text'>
                            Cloud Based Fuel Testing Platform</h5>
                    </nav>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <h5 style={{ margin: 'auto', color: 'red' }} className='d-flex cloud-text'>
                            Please use login button to sign in</h5>
                    </nav>
                </>
            ) :
                (
                    <>
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <img src="image/logoFinal.png" alt='logo' style={{
                                height: '100px', width: '200px', margin: 'auto'
                            }} className='d-flex justify-content-center' />
                            <Button type="primary"
                                className="logout-btn"
                                onClick={logOut}
                            >Logout</Button>
                        </nav>
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <h5 style={{ margin: 'auto' }} className='d-flex cloud-text'>
                                Cloud Based Fuel Testing Platform</h5>
                        </nav>
                        <div className="instructions-container">
                            <Text className="instructions-text">Instructions</Text>
                            <div
                                className="instructions-html"
                                dangerouslySetInnerHTML={{ __html: instructions }}
                            />
                        </div>

                        <Form className="form" name="formvalues" onFinish={onFinish}
                            onFinishFailed={onFinishFailed}>
                            <div className="main-box container py-2">
                                <Row gutter={20}>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="fuelDate" label="Fuel Refill Date:"
                                            rules={[{ required: true, message: 'Please enter date when vehicle was fueled' }]}>
                                            <DatePicker showTime showNow showToday value={fuelDate} onChange={onSelectFuelDate} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="fuelLevel"
                                            rules={[{ required: true, message: 'Please enter fuel level before refill of the vehicle' }]}
                                            label="Fuel Level (Before Refill):"
                                        >
                                            <Select placeholder="Select approximate Fuel Level" value={fuelLevel} onChange={(e) => setFuelLevel(e)}>
                                                <Select.Option value="0-25">0-25%</Select.Option>
                                                <Select.Option value="26-50">26-50%</Select.Option>
                                                <Select.Option value="51-75">51-75%</Select.Option>
                                                <Select.Option value="76-100">76-100%</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="gasStation"
                                            rules={[{ required: true, message: 'Please select gas station (Use Other if your gas station is not in the list)' }]}
                                            label="Gas Station:"
                                        >
                                            <Select placeholder="Select Gas Station. Use Other if not found in the list" onChange={
                                                (e) => {
                                                    console.log("setting gas station", e);
                                                    setSelectedGasStation(e)
                                                }}>
                                                {allGasStations}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="fuelGrade"
                                            rules={[{ required: true, message: 'Please enter fuel grade' }]}
                                            label="Fuel Grade:"
                                        >
                                            <Select placeholder="Select Fuel Grade" onChange={(e) => setFuelGrade(e)}>
                                                <Select.Option value="87">87</Select.Option>
                                                <Select.Option value="89">89</Select.Option>
                                                <Select.Option value="92">92</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                    <Form.Item className="tab" name="numberOfGallons"
                                            rules={[{ required: true , message: 'Number of gallons is required.'},
                                            { validator: validateGallons }]}
                                            label="Number of Gallons Added:">
                                            <Input
                                                value={numberOfGallons}
                                                placeholder="Enter Number of Gallons"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="amount"
                                            rules={[{ required: true, message: 'Please enter amount spent on refill' }]}
                                            label="Amount:">
                                            <InputNumber
                                                value={amount}
                                                onChange={handleAmountChange}
                                                placeholder="Enter amount in dollars and cents"
                                                step={0.01} // Allows entering decimal values with two decimal places
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="refillType"
                                            rules={[{ required: true, message: 'Please select refill type' }]}
                                            label="Refill Type:" >
                                            <Radio.Group onChange={handleRefillTypeChange}>
                                                <Radio value="full">Full (Fill the tank up)</Radio>
                                                <Radio value="partial">Partial (Fill with a set number of gallons)</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="extraClicks"
                                            rules={[
                                                {
                                                    required: refillType === 'full', message: 'Please select extra clicks'
                                                },
                                                {
                                                    validator: validateExtraClicks,
                                                },
                                            ]}
                                            label="Extra Clicks:"
                                        >
                                            {(
                                                <Select
                                                    value={extraClicks}
                                                    onChange={handleExtraClicksChange}
                                                    placeholder="Select number of Extra Clicks after fuel nozzle clicks off"
                                                    disabled={refillType === null || refillType === 'partial'}

                                                >
                                                    <Select.Option value="0">0 - You did NOT fill any more fuel after the fuel nozzle clicked off</Select.Option>
                                                    <Select.Option value="1">1 - You held the fuel nozzle until it clicked off again</Select.Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            className="tab"
                                            name="fuelReceipt"
                                            label="Fuel Receipt"
                                            rules={[{ required: true, message: 'Please select fuel receipt' }]}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Upload beforeUpload={() => false} fileList={fileList} onChange={onFileChange} showUploadList={true} className="upload-btn">
                                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                                </Upload>
                                            <Tooltip title="Please upload a clear picture of your fuel receipt. If the receipt did not print from the fuel dispenser, please ask the cashier to provide the receipt.">
                                                <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                                            </Tooltip>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item className="tab" name="comment"
                                            rules={[{ required: false, message: 'Please enter your comments' }]}
                                            label="Comments:">
                                            <TextArea
                                                value={comment}
                                                onChange={handleCommentChange}
                                                placeholder="Write your comment here..."
                                                rows={4} // Adjust the number of visible rows as needed
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="container">
                                            <Button type="primary" htmlType="submit" className="me-2" disabled={isSubmitting}>
                                                {isSubmitting ? 'Submitting...' : 'Submit'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Form >
                        <div className="footer">
                            <div className="col-xs-6 px-1">
                                <p className="copy">Copyright &copy; 2023</p>
                            </div>
                        </div>
                    </>
                )}
        </>
    );
}