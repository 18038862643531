//@ts-check
import { API, graphqlOperation } from "aws-amplify";
import { getInstruction, getUser } from "../graphql/queries";
import { createInstruction } from "../graphql/mutations";

export const fetchInstructionsForUser = async (user, setInstructions, setGroupId, setVin) => {
    try {
        console.log("fetchInstructionsForUser user:", user);
        const userData = await API.graphql(graphqlOperation(getUser, {id: user}));
        let instructionData;
        setGroupId(userData.data.getUser?.groupId ? userData.data.getUser?.groupId : 1);
        setVin(userData.data.getUser?.vin ? userData.data.getUser?.vin : "unknown");
        if(userData.data.getUser && userData.data.getUser.groupId) {
            console.log("Group id for signed in user:", userData.data.getUser.groupId);
            instructionData = await API.graphql(graphqlOperation(getInstruction, {id: userData.data.getUser.groupId}));
        } else {
            console.log("DB does not contain sign in user. Fetching default instructions")
            instructionData = await API.graphql(graphqlOperation(getInstruction, {id: "1"}));
        }
        console.log("instructionData:", instructionData.data.getInstruction.description);
        setInstructions(instructionData.data.getInstruction.description);
    } catch (error) {
        console.log('error on fetching instructions', error);
    }
};
// Not in use. Keeping here just for future use
export const addInstruction = async ({loginUser}) => {
    try {
        console.log(`addInstruction started`);
        const createInstructionInput = {
            id: loginUser,
            description: "Here is set of instructions for loggedin user",
        };
        console.log(`going to save:{createInstructionInput}`);
        await API.graphql(graphqlOperation(createInstruction, { input: createInstructionInput }));
        console.log('instruction created');
    } catch (error) {
        console.log('error on creating instruction', error);
    }
};

