import React, { useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

import { Login } from './components/auth/Login';
import { SignUp } from './components/auth/SignUp';
// import { RegisterAndLogin } from './components/auth/RegisterAndLogin';
import { Home } from './components/Home';
import { SaveConfirmation } from './components/SaveConfirmation';
import EmailConfirmation from './components/auth/EmailConfirmation';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

/*
  To enable signup:
    Comment the following:
      import { Login } from './components/auth/Login';
      <Route path="/login" element={<Login setLoginUser={setLoginUser}/>} />
    Uncomment the following:
      import { RegisterAndLogin } from './components/auth/RegisterAndLogin';
      <Route path="/login" element={<RegisterAndLogin/>} />

*/
function MyRoutes() {
  const [loginUser, setLoginUser] = useState('');
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path='/' element={<Login setLoginUser={setLoginUser} />} /> */}
        <Route path="/" element={<Login setLoginUser={setLoginUser}/>} />
        <Route path="/login" element={<Login setLoginUser={setLoginUser}/>} />
        {/* <Route path="/login" element={<RegisterAndLogin/>} /> */}
        <Route path="/home" element={
              <Home loginUser={loginUser}/>
          }
        />
        <Route path="/saveconfirmation" element={
              <SaveConfirmation loginUser={loginUser}/>
          }
        />
        <Route path="/signup" element={
              <SignUp/>
          }
        />
        <Route path="/emailconfirmation" element={
              <EmailConfirmation/>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;