//@ts-check
import React from "react";
import {
    Select
} from "antd";

import { API, graphqlOperation } from "aws-amplify";
import { listGasStations } from "../graphql/queries";

export const loadAllGasStations = async (setAllGasStations) => {
    try {
        const gasStationsData = await API.graphql(graphqlOperation(listGasStations));
        let gasStations = []
        for(let gasStation of gasStationsData.data.listGasStations.items) {
            gasStations.push(gasStation.name);
        }
        console.log("sorted gasStations:", gasStations.sort());
        let gasStationOptions = []
        gasStations.sort().forEach(station => { gasStationOptions.push(<Select.Option key={station} value={station}>{station}</Select.Option>)});
        setAllGasStations(gasStationOptions);
    } catch (error) {
        console.log('error on fetching all gas stations', error);
    }
};
