import React, { useState } from 'react';
import { Form, Input, Button, message, Row, Col } from 'antd';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

const EmailConfirmation = () => {
  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState('');
  const location = useLocation();
  const onFinish = async () => {
    try {
      console.log("location:", location);
      const email = location.state.email;
      console.log("email:", email, "verificationCode", verificationCode);
      await Auth.confirmSignUp(email, verificationCode);
      message.success('Email verification successful! You can now sign in.');
      navigate('/');
    } catch (error) {
      console.error('Error confirming email:', error);
      message.error('Error confirming email. Please check the verification code and try again.');
    }
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <img src="image/logoFinal.png" alt='logo' style={{
          height: '100px', width: '200px', margin: 'auto'
        }} className='d-flex justify-content-center' />
      </nav>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <h5 style={{ margin: 'auto' }} className='d-flex cloud-text'>
          Cloud Based Fuel Testing Platform</h5>
      </nav>
      <Form onFinish={onFinish}>
        <div className="main-box container py-2">
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <div className="container">
                <Form.Item
                  label="Verification Code"
                  name="verificationCode"
                  rules={[{ required: true, message: 'Please input the verification code!' }]}
                >
                  <Input value={verificationCode} onChange={handleVerificationCodeChange} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} lg={12}>
              <div className="container">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Confirm Email
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default EmailConfirmation;
