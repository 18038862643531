/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInstruction = /* GraphQL */ `
  mutation CreateInstruction(
    $input: CreateInstructionInput!
    $condition: ModelInstructionConditionInput
  ) {
    createInstruction(input: $input, condition: $condition) {
      id
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInstruction = /* GraphQL */ `
  mutation UpdateInstruction(
    $input: UpdateInstructionInput!
    $condition: ModelInstructionConditionInput
  ) {
    updateInstruction(input: $input, condition: $condition) {
      id
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInstruction = /* GraphQL */ `
  mutation DeleteInstruction(
    $input: DeleteInstructionInput!
    $condition: ModelInstructionConditionInput
  ) {
    deleteInstruction(input: $input, condition: $condition) {
      id
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      groupId
      vin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      groupId
      vin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      groupId
      vin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGasStation = /* GraphQL */ `
  mutation CreateGasStation(
    $input: CreateGasStationInput!
    $condition: ModelGasStationConditionInput
  ) {
    createGasStation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGasStation = /* GraphQL */ `
  mutation UpdateGasStation(
    $input: UpdateGasStationInput!
    $condition: ModelGasStationConditionInput
  ) {
    updateGasStation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGasStation = /* GraphQL */ `
  mutation DeleteGasStation(
    $input: DeleteGasStationInput!
    $condition: ModelGasStationConditionInput
  ) {
    deleteGasStation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFuelData = /* GraphQL */ `
  mutation CreateFuelData(
    $input: CreateFuelDataInput!
    $condition: ModelFuelDataConditionInput
  ) {
    createFuelData(input: $input, condition: $condition) {
      id
      email
      vin
      time
      fuelLevel
      gasStation
      fuelGrade
      gallons
      cost
      refillType
      extraClicks
      fuelReceiptLocation
      fuelReceiptText
      comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFuelData = /* GraphQL */ `
  mutation UpdateFuelData(
    $input: UpdateFuelDataInput!
    $condition: ModelFuelDataConditionInput
  ) {
    updateFuelData(input: $input, condition: $condition) {
      id
      email
      vin
      time
      fuelLevel
      gasStation
      fuelGrade
      gallons
      cost
      refillType
      extraClicks
      fuelReceiptLocation
      fuelReceiptText
      comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFuelData = /* GraphQL */ `
  mutation DeleteFuelData(
    $input: DeleteFuelDataInput!
    $condition: ModelFuelDataConditionInput
  ) {
    deleteFuelData(input: $input, condition: $condition) {
      id
      email
      vin
      time
      fuelLevel
      gasStation
      fuelGrade
      gallons
      cost
      refillType
      extraClicks
      fuelReceiptLocation
      fuelReceiptText
      comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
