//@ts-check
import React from 'react';
import '../index.css';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button } from 'antd';

export function SaveConfirmation({ loginUser }) {
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();
    function logOut() {
        signOut();
        navigate('/login');
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <img src="image/logoFinal.png" alt='logo' style={{
                    height: '100px', width: '200px', margin: 'auto'
                }} className='d-flex justify-content-center' />
                <Button type="primary"
                    className="logout-btn"
                    onClick={logOut}
                >Logout</Button>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <h5 style={{ margin: 'auto' }} className='d-flex cloud-text'>
                    Cloud Based Fuel Testing Platform</h5>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <h6 style={{ margin: 'auto', color: 'blue' }} className='d-flex cloud-text'>
                    Data successfully saved. Thank you!</h6>
            </nav>
            <div className="container">
                <Button type="primary" onClick={() => navigate('/home')}>Back to Home</Button>
            </div>
            <div className="footer">
                <div className="col-xs-6 px-1">
                    <p className="copy">Copyright &copy; 2023</p>
                </div>
            </div>
        </>
    );
}