import React from 'react';
import { Form, Input, Button } from 'antd';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { notification } from 'antd';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

Amplify.configure(awsconfig);

export const Login = ({ setLoginUser }) => {
    const navigate = useNavigate()
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();

    let from = location.state?.from?.pathname || '/home';
    useEffect(() => {
        console.log('route value in login', route);
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    const onFinish = async values => {
        if (!values) {
            console.log("E-mail and Password are required for login");
            notification.error({
                description: "Failed to sign in. Please provide E-mail and Password"
            })
            return;
        }

        const { email, password } = values;
        const lowerCasedEmail = email.toLowerCase();

        try {
            let user = await Auth.signIn(lowerCasedEmail, password);
            console.log("Signed in user email: " + user.attributes.email);
            setLoginUser(user.attributes.email);
            notification.open({
                description: "Successfully Signed in",
            })
            console.log('route value before navigate to home', route);
            navigate('/home', { replace: true });
        } catch (error) {
            console.log("Error happened during login", error);
            notification.error({
                description: "Failed to sign in. Please check your E-mail and Password"
            })
        }
    }
    const onFinishFailed = errorInfo => {
        // Get the error message from the errorInfo object
        const errorMessage = errorInfo.errorFields[0].errors[0];
        notification.error({
          description: errorMessage,
        });
      };
      
    return (
        <div className='container-fluid box '>
            <div>
                <div style={{ textAlign: 'right' }}>
                    <Link to="/signup">Create account</Link>
                </div>
                <div className='m-5'>
                    <img src="image/logoFinal.png" alt='logo' className='main-logo-logoin-page' style={{
                        height: '120px', width: '200px'
                    }} />
                </div>
                <div className='d-flex justify-content-center m-4'>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }
                        }
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[{ required: true, message: 'Please input your e-mail!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        < Form.Item wrapperCol={{
                            offset: 8,
                            span: 16,
                        }} >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>

                </div>
            </div>
        </div>
    );
};
