import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export const SignUp = () => {
    const navigate = useNavigate()
    const onFinish = async (values) => {
        try {
            const { email, password } = values;
            const lowerCasedEmail = email.toLowerCase();

            const signUpResponse = await Auth.signUp({
                username: lowerCasedEmail,
                password,
            });

            if (signUpResponse.userConfirmed) {
                message.success('Sign up successful!');
            } else {
                message.success('Sign up successful! Please check your email for verification.');
                console.log(`SignUp - navigating to emailconfirmation with ${lowerCasedEmail}`);
                navigate("/emailconfirmation", { state: { email: lowerCasedEmail } });
            }
        } catch (error) {
            console.error('Error signing up:', error);
            message.error('Error signing up. ' + error.message);
        }
    };

    return (
        <div className='container-fluid box '>
            <div>
                <div style={{ textAlign: 'right' }}>
                    <Link to="/login">Back to login</Link>
                </div>
                <div className='m-5'>
                    <img src="image/logoFinal.png" alt='logo' className='main-logo-logoin-page' style={{
                        height: '120px', width: '200px'
                    }} />
                </div>
                <div className='d-flex justify-content-center m-4'>
                    <Form onFinish={onFinish}>
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[{ required: true, message: 'Please input your e-mail!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                { required: true, message: 'Please input your password!' },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        < Form.Item wrapperCol={{
                            offset: 8,
                            span: 16,
                        }} >
                            <Button type="primary" htmlType="submit">
                                Sign Up
                            </Button>
                        </Form.Item>

                    </Form>
                </div>
            </div>
        </div>
    );
};

