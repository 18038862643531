/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:77341af6-94e7-44ed-840b-05564546187e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_33Ugk2ThO",
    "aws_user_pools_web_client_id": "1mft34k4rjnt92upr4q2hj1rd2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://36lu3rm5d5czxfhjev5liane4y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "fuelestimationwebappcf8abd61fb8e4fc19a97024ca36110131-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_content_delivery_bucket": "fuelestimationwebapp-20230810174215-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1nzivn0n5f3qz.cloudfront.net",
    "predictions": {
        "identify": {
            "identifyText": {
                "proxy": false,
                "region": "us-east-2",
                "defaults": {
                    "format": "PLAIN"
                }
            }
        }
    }
};


export default awsmobile;
